import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "react-spring";
import ReactPlayer from "react-player";
import { useWindowSize } from "@react-hook/window-size";

function HomeVideo({_videoLoadedCallback, videoStopped}) {
  const [width, height] = useWindowSize()
  let scale = 1.0;
  if (width/height > 16/9) {
    scale = (width / (16/9)) / height
  } else {
    scale = (height * (16/9)) / width
  }
  const [props, set, stop] = useSpring(() => ({config: config.slow, opacity: 0, transform: `scale(${scale + 0.3})`}))

  useEffect(() => {
    if (videoStopped) {
      set({opacity: 0, transform: `scale(${scale + 0.3})`})
    }
  });
  
  const videoLoaded = () => {
    
    setTimeout(() => {
      _videoLoadedCallback();
      set({opacity: 1, transform: `scale(${scale + 0.1})`})
    }, 1000);
  }

  return (
    <animated.div className="home-video-container" style={props}>
      <ReactPlayer
        url="https://vimeo.com/454762177"
        playing
        controls={false}
        loop
        width="100%"
        height="100%"
        muted
        onReady={videoLoaded}
      />
    </animated.div>
  )
}

export default HomeVideo;
