import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import {Img as ReactImg} from 'react-image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScaleLoader from "react-spinners/ScaleLoader";
import {isMobile} from 'react-device-detect';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import SEO from "../components/seo";
import { Slug, Fade } from '../components/primitives';
import HomeVideo from '../components/home-video';


class RootIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoLoading: !isMobile,
      videoLoaded: false,
      videoStopped: false,
      posts: []
    };
  }

  componentDidMount() {
    let posts = [];
    fetch(`https://graph.instagram.com/me/media?fields=media_url,media_type&access_token=${process.env.GATSBY_INSTAGRAM_API}`)
      .then(response => response.json())
      .then(json => {
        json.data.forEach(post => {
          if (post.media_type === "IMAGE") {
            posts.push({
              id: post.id,
              url: post.media_url
            });
          }
        });

        this.setState({
          posts
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  getText = key => {
    const arr = this.props.data.allContentfulTitle.edges;
    const node = arr.find(n => n.node.key === key);

    return node ? node.node.text.text : null;
  }

  videoLoadedCallback = () => {
    this.setState({
      videoLoaded: true
    })
  }

  render() {
    const { posts, videoLoading, videoLoaded, videoStopped } = this.state;

    const showPlayButton = !videoLoading;

    const swiperParams = {
      slidesPerView: 'auto',
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      autoplay: {
        delay: 10000,
      }
    }

    const homeBlocks = this.props.data.allContentfulHomeBlock.edges.sort((a, b) => a.node.order - b.node.order);

    return (
      <>
        <SEO title="Pommes biologiques certifiées par Québec Vrai" />

        <ParallaxBanner
          className="bg-ref-dark"
          layers={[
            {
              amount: 0.5,
              children: (
                <div className="parallax-children-50">
                  <Img
                    fluid={this.props.data.landingImage.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    style={{ height: '100%' }}
                  />
                  {videoLoading && <HomeVideo _videoLoadedCallback={this.videoLoadedCallback} videoStopped={videoStopped} />}
                </div>
              )
            }
          ]}
          style={{
            height: "100vh"
          }}
        >
          <div className="parallax-overlay home-overlay">
            <div className="wrapper wrapper-home-overlay">
              <div className="home-overlay-text">
                <h3>{this.getText('homepage-subtitle')}</h3>
                <h1>{this.getText('homepage-title')}</h1>
                <Link to="/services/" className="home-link link-white">
                  {this.getText('homepage-link1')}
                </Link>
                <Link to="/pommes/" className="home-link link-white">
                  {this.getText('homepage-link2')}
                </Link>
              </div>
            </div>

            
            {(!isMobile && !videoLoaded) && (
              <>
                <Fade show={showPlayButton} delay={0}>
                  <div className="home-play-button" onClick={() => this.setState({videoLoading : true})}>
                    <FontAwesomeIcon icon="play" className="home-play-button-icon" />
                  </div>
                </Fade>
                  
                <Fade show={videoLoading} delay={1000}>
                  <div className="home-video-loading">
                    <Slug delay={200}>
                      <ScaleLoader color="#fff" />
                    </Slug>
                  </div>
                </Fade>
              </>
            )}
            {(!isMobile && videoLoaded && !videoStopped) && (
              <div className="home-close-button-container">
                <div className="home-close-button" onClick={() => this.setState({videoStopped : true})}>
                  <FontAwesomeIcon icon="times" className="home-play-button-icon" />
                </div>
              </div>
            )}
          </div>
        </ParallaxBanner>

        <div className="wrapper section bg-ref-light">
          <div className="h1 text-primary">{this.getText('homepage-banner')}</div>
          {/* <div className="home-banner-container">
            <div className="home-banner">
              <h3 className="text-bold">{this.getText('home-banner-title')}</h3>
              <div className="home-banner-text">Profitez de nos dernières journées d'ouverture pour vous procurer vos pommes biologiques <b>les samedis 21 et 28 novembre!</b></div>
            </div>
          </div> */}
          <div className="home-news-container">
            {homeBlocks.map(b => <div className="home-news" key={"home-block-" + b.node.order}>
              {b.node.new && <h3 className="home-news-span">Nouveauté!</h3>}
              <h3 className="home-news-title">{b.node.title}</h3>
              <div className="home-news-description">{documentToReactComponents(b.node.description.json)}</div>
            </div>)}
          </div>
        </div>

        <ParallaxBanner
          className="bg-ref-dark"
          style={{
            height: "100vh"
          }}
          layers={[
            {
              // image: 'vj-bg-01.jpg',
              amount: 0.2,
              children: (
                <div className="parallax-children-plus20">
                  <Img
                    fluid={this.props.data.home01.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    style={{ height: '100%' }}
                  />
                </div>
              )
            }
          ]}
        >
          <div className="parallax-overlay">
            <div className="wrapper wrapper-home-overlay">
              <div className="home-overlay-text">
                <h3>{this.getText('homepage-2-subtitle')}</h3>
                <div className="h1">{this.getText('homepage-2-title')}</div>
                <Link to="/pommes/" className="home-link link-white">
                  {this.getText('homepage-2-link1')}
                </Link>
                <Link to="/blog/" className="home-link link-white">
                  {this.getText('homepage-2-link2')}
                </Link>
              </div>
            </div>
          </div>
        </ParallaxBanner>

        <div className="section insta-section bg-ref-light">
          <div className="wrapper">
            <div className="section-header">
              <h2>Photos</h2>
              <a className="home-link text-bold text-right" href="https://www.instagram.com/vergerbio/" target="_blank">
                {this.getText('homepage-ig-link')} <FontAwesomeIcon icon="external-link-alt" /><br></br>@vergerbio
              </a>
            </div>
          </div>

          {posts.length > 0 && <Swiper {...swiperParams}>
            {posts.map(post => (
              <div key={'insta-img-' + post.id}>
                <ReactImg src={post.url}></ReactImg>
              </div>
            ))}
          </Swiper>}
        </div>

        {/* <div className="wrapper">
          <h2 className="section-headline">Recent articles</h2>
          <ul className="article-list">
            {posts.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ul>
        </div> */}
      </>
    );
  }
}

export default RootIndex;

export const query = graphql`
  query {
    landingImage: file(relativePath: { eq: "video-first-frame.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    home01: file(relativePath: { eq: "apple_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allContentfulTitle {
      edges {
        node {
          key
          text {
            text
          }
        }
      }
    }
    allContentfulService {
      edges {
        node {
          order
          icon
          title
          description {
            description
          }
        }
      }
    }
    allContentfulHomeBlock {
      edges {
        node {
          order
          title
          new
          description {
            json
          }
        }
      }
    }
  }
`;